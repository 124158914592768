import React, { useState } from 'react';
import MonacoEditor from '@monaco-editor/react';
import ReactJson from 'react-json-view';

import Breadcrumb from '../../../../../components/Breadcrumb/Breadcrumb';

const PostProcessing: React.FC = () => {
    const [code, setCode] = useState("// Write your code here");

    // Sample input and output data
    const sampleInput = {
        "name": "John Doe",
        "age": 30,
        "email": "john.doe@example.com",
        // Add more data if needed to test scrollability
    };

    const sampleOutput = {
        "status": "success",
        "message": "Data processed successfully",
        // Add more data if needed to test scrollability
    };

    return (
        <>
            {/* Breadcrumb */}
            <Breadcrumb pageName='Post-Processing' />

            {/* Wrapped everything inside a card */}
            <div className="card-body">
                {/* Input and Output Boxes */}
                <div className="flex flex-wrap -mx-2 mb-4">
                    {/* Input Box */}
                    <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                        <div className="border border-gray-300 rounded-md h-full">
                            <div className="p-4">
                                <h2 className="text-xl font-semibold mb-2">Input</h2>
                                <div className="overflow-auto max-h-64 min-h-[200px] flex flex-col h-full">
                                    <ReactJson
                                        src={sampleInput}
                                        name={null}
                                        collapsed={false}
                                        displayDataTypes={false}
                                        enableClipboard={false}
                                        theme="monokai"
                                        style={{ minHeight: '200px' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Output Box */}
                    <div className="w-full md:w-1/2 px-2">
                        <div className="border border-gray-300 rounded-md h-full">
                            <div className="p-4">
                                <h2 className="text-xl font-semibold mb-2">Output</h2>
                                <div className="overflow-auto max-h-64 min-h-[200px] flex flex-col h-full">
                                    <ReactJson
                                        src={sampleOutput}
                                        name={null}
                                        collapsed={false}
                                        displayDataTypes={false}
                                        enableClipboard={false}
                                        theme="monokai"
                                        style={{ minHeight: '200px' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Code Editor */}
                <div className="mb-4">
                    <div className="flex items-center justify-between mb-2">
                        <h2 className="text-xl font-semibold">Code</h2>
                        <div className="form-control">
                            <label
                                className="label cursor-pointer tooltip tooltip-left flex items-center"
                                data-tip="If activated, code will be applied to all entries"
                            >
                                <span className="label-text mr-2">Activated</span>
                                <input type="checkbox" className="checkbox" />
                            </label>
                        </div>
                    </div>
                    <div className="border border-gray-300 rounded">
                        <MonacoEditor
                            height="500px"
                            width="100%"
                            language="python"
                            theme="vs-dark"
                            value={code}
                            onChange={(value: string | undefined) => setCode(value || '')}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PostProcessing;
