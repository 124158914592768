import React from 'react';
import { useNavigate } from 'react-router-dom';

const ChangePasswordPage: React.FC = () => {
    const navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Add your password change logic here
        // If successful, navigate to the PasswordChanged page
        try {
            navigate('/password-changed');
        } catch (error) {
            console.error('Error navigating to password-changed:', error);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="flex flex-col md:flex-row w-full max-w-5xl shadow-lg rounded-lg md:min-h-[640px]">
                {/* Left Column */}
                <div className="hidden md:flex flex-col justify-center items-center bg-blue-600 text-white p-5 rounded-l-lg w-full md:w-1/2">
                    <h1 className="text-4xl font-bold">ParseTrue</h1>
                    <p className="mt-3 text-xl">Fast, Efficient and Productive</p>
                </div>

                {/* Right Column */}
                <div className="bg-white p-6 md:w-1/2 w-full rounded-r-lg flex items-center justify-center">
                    <div className="max-w-md mx-auto">
                        <h2 className="text-center text-xl font-semibold mb-4">Setup New Password</h2>
                        <p className="text-center text-gray-500 mb-6">
                            Have you already reset the password?{' '}
                            <a href="#signin" className="text-blue-600 hover:underline">
                                Sign in
                            </a>
                        </p>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="formPassword" className="block text-gray-700 mb-2">Password</label>
                                <input
                                    id="formPassword"
                                    type="password"
                                    placeholder="Password"
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                <p className="text-gray-500 text-sm mt-1">Use 8 or more characters with a mix of letters, numbers & symbols.</p>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="formConfirmPassword" className="block text-gray-700 mb-2">Repeat Password</label>
                                <input
                                    id="formConfirmPassword"
                                    type="password"
                                    placeholder="Repeat Password"
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="mb-4 flex items-center">
                                <input
                                    id="formTerms"
                                    type="checkbox"
                                    className="mr-2"
                                />
                                <label htmlFor="formTerms" className="text-gray-700">
                                    I Agree &{' '}
                                    <a href="#terms" className="text-blue-600 hover:underline">
                                        Terms and conditions
                                    </a>.
                                </label>
                            </div>
                            <button
                                type="submit"
                                className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-300"
                            >
                                Submit
                            </button>
                        </form>
                        <div className="text-center mt-6">
                            <a href="#language" className="text-blue-600 hover:underline mr-3">Home</a>
                            <a href="#terms" className="text-blue-600 hover:underline mr-3">Terms</a>
                            <a href="#plans" className="text-blue-600 hover:underline mr-3">Plans</a>
                            <a href="#contact" className="text-blue-600 hover:underline">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePasswordPage;