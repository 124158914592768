import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';

const CreateInboxForm: React.FC = () => {
    const [inboxName, setInboxName] = useState('');
    const [parseEngine, setParseEngine] = useState('None');
    const [tags, setTags] = useState([]);

    const proprietaryOptions: string[] = [];
    const byoeOptions: string[] = ["CustomEngine 1"];

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (parseEngine === 'None') {
            alert("Please select a parse engine.");
            return;
        }
        // Handle form submission logic here
        // Redirect to inboxes
    };

    const handleTagsChange = (newValue: any) => {
        setTags(newValue);
    };

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-2xl p-6 bg-white shadow-md rounded-lg mr-auto">
                <h1 className="text-2xl font-bold mb-2">Create an Inbox</h1>
                <p className="mb-4 text-gray-600">You can use inboxes to gather and manage similar documents, such as PDF invoices or emails from Airbnb. If you need to sort various document types, simply set up additional inboxes for each category!
                    This approach allows for better organization and accessibility of your documents. For more tips on managing your documents effectively, you can check out additional resources.</p>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="inboxName">
                            Inbox Name
                        </label>
                        <p className="text-gray-600 text-sm mb-2">Give your inbox a name.</p>
                        <input
                            id="inboxName"
                            type="text"
                            value={inboxName}
                            onChange={(e) => setInboxName(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="parseEngine">
                            Parse Engine
                        </label>
                        <p className="text-gray-600 text-sm mb-2">Choose the engine for document parsing. The default option is our proprietary engines, but you also have the option to select any engines you've configured under Parse Engine. <b>You can change this later</b></p>
                        <select
                            id="parseEngine"
                            value={parseEngine}
                            onChange={(e) => setParseEngine(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        >
                            <option value="None" className="italic">None</option>
                            {proprietaryOptions.length > 0 ? (
                                proprietaryOptions.map((option, index) => (
                                    <option key={index} value={option} className="italic">{option}</option>
                                ))
                            ) : (
                                <option value="" disabled className="italic">Your plan does not support proprietary options</option>
                            )}
                            <option value="" disabled className="italic">──────────</option>
                            {byoeOptions.length > 0 ? (
                                byoeOptions.map((option, index) => (
                                    <option key={index} value={option} className="italic">{option}</option>
                                ))
                            ) : (
                                <option value="" disabled className="italic">No bring your own engines configured</option>
                            )}
                        </select>
                        {byoeOptions.length === 0 && (
                            <p className="text-blue-500 mt-2">
                                <Link to="/dashboard/parse-engine">Click here to configure Parse Engine</Link>
                            </p>
                        )}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tags">
                            Tags (Optional)
                        </label>
                        <p className="text-gray-600 text-sm mb-2">Choose or create tags for your inbox. Tags assist you in quickly finding your inbox through search functionality. This helps streamline your document organization and retrieval process.</p>
                        <CreatableSelect
                            isMulti
                            onChange={handleTagsChange}
                            value={tags}
                            className="w-full"
                        />
                    </div>
                    <div className="flex justify-between items-center">
                        <Link to="/dashboard/inboxes" className="btn btn-outline">
                            Back to Inboxes
                        </Link>
                        <button type="submit" className="btn btn-primary">
                            Create
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateInboxForm;