import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

interface HeaderProps {
    title: string;
    showLoginButton: boolean;
}

const Header: React.FC<HeaderProps> = ({ title, showLoginButton }) => {
    const { isAuthenticated, logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    return (
        <div className="w-full bg-base-200">
            <div className="navbar container mx-auto px-4">
                <div className="navbar-start">
                    <Link to="/" className="btn btn-ghost normal-case text-xl">
                        {title}
                    </Link>
                </div>
                <div className="navbar-center hidden lg:flex">
                    <ul className="menu menu-horizontal px-1">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/blog">Blog</Link>
                        </li>
                    </ul>
                </div>
                <div className="navbar-end">
                    {isAuthenticated ? (
                        <>
                            <Link to="/dashboard" className="btn btn-outline mr-2">
                                Dashboard
                            </Link>
                            <button onClick={handleLogout} className="btn btn-outline btn-error">
                                Logout
                            </button>
                        </>
                    ) : (
                        showLoginButton && (
                            <Link to="/login" className="btn btn-outline">
                                Login
                            </Link>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default Header;