import React from 'react';

const ForgetPasswordPage: React.FC = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="flex flex-col md:flex-row w-full max-w-5xl shadow-lg rounded-lg md:min-h-[640px]">
                {/* Left Column */}
                <div className="hidden md:flex flex-col justify-center items-center bg-blue-600 text-white p-5 rounded-l-lg w-full md:w-1/2">
                    <h1 className="text-4xl font-bold">ParseTrue</h1>
                    <p className="mt-3 text-xl">Fast, Efficient and Productive</p>
                </div>

                {/* Right Column */}
                <div className="bg-white p-6 md:w-1/2 w-full rounded-r-lg flex items-center justify-center">
                    <div className="max-w-md mx-auto">
                        <h2 className="text-center text-2xl font-semibold mb-4">Forget Password</h2>
                        <p className="text-center text-gray-500 mb-6">Enter your email to reset your password.</p>
                        <form>
                            <div className="mb-4">
                                <input
                                    type="email"
                                    placeholder="Email"
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <div className="flex justify-start space-x-2">
                                <button
                                    type="submit"
                                    className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300"
                                >
                                    Submit
                                </button>
                                <button
                                    type="button"
                                    className="bg-gray-200 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-300 transition duration-300"
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>

                        {/* Footer */}
                        <div className="mt-4 text-center text-sm text-gray-500">
                            <p>
                                Don't have an Account?{' '}
                                <a href="/create-account" className="text-blue-600 hover:underline">
                                    Create one here
                                </a>.
                            </p>
                            <p>
                                Forgot your password?{' '}
                                <a href="/reset-password" className="text-blue-600 hover:underline">
                                    Reset it here
                                </a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgetPasswordPage;