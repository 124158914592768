import React, { useState } from 'react';

const AdvancedTab: React.FC = () => {
    const [failedDocAlert, setFailedDocAlert] = useState('12 hours');
    const [failedWebhookAlert, setFailedWebhookAlert] = useState('6 hours');
    const [destinationInbox, setDestinationInbox] = useState('no other inboxes');
    const [parseURLs, setParseURLs] = useState(false);
    const [parseImgSrc, setParseImgSrc] = useState(false);

    return (
        <div className="p-4 max-w-3xl mx-0"> 
            {/* Email Notifications */}
            <h2 className="text-2xl font-semibold mb-4">Email Notifications</h2>
            <div className="mb-6">
                <label className="block font-medium mb-2">Email alerts: Failed documents</label>
                <select
                    className="select select-bordered w-full max-w-xs mb-2"
                    value={failedDocAlert}
                    onChange={(e) => setFailedDocAlert(e.target.value)}
                >
                    <option>12 hours</option>
                    <option>24 hours</option>
                    <option>48 hours</option>
                </select>
                <p className="text-sm text-gray-600">
                    Email alerts are sent whenever Airparser is unable to parse an incoming email or document. The above value represents the minimum time period between two email alerts.
                </p>
            </div>
            <div className="mb-6">
                <label className="block font-medium mb-2">Email alerts: Failed webhooks & integrations</label>
                <select
                    className="select select-bordered w-full max-w-xs mb-2"
                    value={failedWebhookAlert}
                    onChange={(e) => setFailedWebhookAlert(e.target.value)}
                >
                    <option>6 hours</option>
                    <option>12 hours</option>
                    <option>24 hours</option>
                </select>
                <p className="text-sm text-gray-600">
                    Email alerts are sent whenever Airparser is unable to export data to a webhook or integration. The above value represents the minimum time period between two email alerts.
                </p>
            </div>
            <button className="btn btn-primary mb-6">Save</button>

            {/* Clone the Extraction Schema */}
            <h2 className="text-2xl font-semibold mb-4">Clone the Extraction Schema</h2>
            <div className="mb-6">
                <label className="block font-medium mb-2">Destination inbox</label>
                <select
                    className="select select-bordered w-full max-w-xs mb-2"
                    value={destinationInbox}
                    onChange={(e) => setDestinationInbox(e.target.value)}
                >
                    <option>no other inboxes</option>
                    {/* Add other inbox options here */}
                </select>
                <p className="text-sm text-gray-600">
                    You can easily clone the extraction schema from this inbox (6 fields in the extraction schema, last update: 21 days ago) to a destination inbox. Cloning the schema will overwrite the existing schema in the destination inbox. This action is irreversible, so please proceed with caution.
                </p>
                <button className="btn btn-outline btn-primary">Clone</button>
            </div>

            {/* Advanced Settings */}
            <h2 className="text-2xl font-semibold mb-4">Advanced Settings</h2>
            <p className="text-sm text-gray-600 mb-4">
                Make sure you understand before changing anything here. Changes might cause unexpected results. Contact us for assistance if uncertain.
            </p>
            <div className="mb-6">
                <label className="cursor-pointer flex items-center mb-2">
                    <input
                        type="checkbox"
                        className="checkbox checkbox-primary mr-2"
                        checked={parseURLs}
                        onChange={() => setParseURLs(!parseURLs)}
                    />
                    <span className="label-text font-semibold">Parse URLs</span>
                </label>
                <label className="cursor-pointer flex items-center">
                    <input
                        type="checkbox"
                        className="checkbox checkbox-primary mr-2"
                        checked={parseImgSrc}
                        onChange={() => setParseImgSrc(!parseImgSrc)}
                    />
                    <span className="label-text font-semibold">Parse 'img src' attributes</span>
                </label>
            </div>
            <button className="btn btn-primary mb-6">Save</button>

            {/* Danger Zone */}
            <h2 className="text-2xl font-semibold text-red-600 mb-4">Danger Zone</h2>
            <p className="text-sm text-gray-600 mb-4">
                Once you delete the inbox, all documents and parsed data will be lost. If you have email auto-forwarding enabled, turn it off before deleting the inbox!
            </p>
            <button className="btn btn-error">Delete Inbox</button>
        </div>
    );
};

export default AdvancedTab;
