import React from 'react';

interface TimelineEvent {
    time: string;
    title: string;
    description: string;
    badgeColor: 'success' | 'warning' | 'danger' | 'primary'; // Define badge types
}

const timelineEvents: TimelineEvent[] = [
    {
        time: '9:30 AM',
        title: 'Meeting with client',
        description: 'Meeting with USA Client, today at 12:00 PM',
        badgeColor: 'success',
    },
    {
        time: '12:25 PM',
        title: 'Meeting with UK client',
        description: 'Another meeting with UK client today at 3:00 PM',
        badgeColor: 'warning',
    },
    {
        time: '6:00 PM',
        title: 'Discussion with team',
        description: 'Discussion with team about new product launch.',
        badgeColor: 'danger',
    },
    {
        time: '9:00 AM',
        title: 'Discussion with marketing team',
        description: 'Discussion about the popularity of last product.',
        badgeColor: 'primary',
    },
];

const Timeline: React.FC = () => {
    return (
        <div className="max-w-4xl mx-auto p-3 overflow-x-auto min-w-[530px]">
            {/* Header */}
            <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                <h5 className="font-bold">Events</h5>
            </div>
            <div className="mb-5">
                <div className="relative">
                    {/* Vertical Line */}
                    <div className="absolute top-0 left-4 w-1 bg-gray-200 h-full rounded"></div>
                    {timelineEvents.map((event, index) => (
                        <div key={index} className="relative pl-8 mb-6">
                            {/* Badge */}
                            <span
                                className={`absolute left-1 top-1 w-3 h-3 rounded-full ${getBadgeColor(event.badgeColor)} shadow-md`}
                            ></span>
                            {/* Content */}
                            <div className="ml-4">
                                <h5 className="text-md font-bold">{event.title}</h5>
                                <p className="text-sm">{event.description}</p>
                                <span className="block text-xs text-gray-500 mt-1">{event.time}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

// Function to get TailwindCSS badge color
const getBadgeColor = (badgeColor: 'success' | 'warning' | 'danger' | 'primary'): string => {
    switch (badgeColor) {
        case 'success':
            return 'bg-green-500';
        case 'warning':
            return 'bg-yellow-500';
        case 'danger':
            return 'bg-red-500';
        case 'primary':
            return 'bg-blue-500';
        default:
            return 'bg-gray-500';
    }
};

export default Timeline;
