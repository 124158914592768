import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PasswordChanged: React.FC = () => {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown <= 1) {
                    navigate('/signin');
                    clearInterval(timer);
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [navigate]);

    const handleRedirect = () => {
        navigate('/login');
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="card bg-white shadow-lg rounded-lg p-6 w-full max-w-md mx-auto md:min-h-[640px] flex flex-col justify-center">
                <h3 className="text-center text-2xl font-semibold mb-4">Password Changed Successfully</h3>
                <p className="text-center text-gray-500 mb-6">
                    Go back to login or be redirected in {countdown} seconds.
                </p>
                <button
                    onClick={handleRedirect}
                    className="btn btn-primary w-full"
                >
                    Go to Login
                </button>
            </div>
        </div>
    );
};

export default PasswordChanged;
