import React, { useState } from 'react';
import { FaEdit } from 'react-icons/fa';

const GeneralTab: React.FC = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    // Edit name
    const [isEditingName, setIsEditingName] = useState(false);
    const [name, setName] = useState('john'); // TODO: Replace with your actual user name
    const [tempName, setTempName] = useState(name);

    // Edit email
    const [isEditingEmail, setIsEditingEmail] = useState(false);
    const [email, setEmail] = useState('john@gmail.com');
    const [tempEmail, setTempEmail] = useState(email);

    // Handle password changes
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // Add logic here to handle password update
        if (newPassword === confirmPassword) {
            console.log('Password updated successfully!');
        } else {
            console.log('Passwords do not match!');
        }
    };

    // Handle name changes
    const handleNameEdit = () => {
        setIsEditingName(true);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempName(event.target.value);
    };

    const handleNameSave = () => {
        console.log('Name updated to:', tempName);
        setName(tempName);
        setIsEditingName(false);
    };

    const handleNameCancel = () => {
        setTempName(name);
        setIsEditingName(false);
    };

    // Handle email changes
    const handleEmailEdit = () => {
        setIsEditingEmail(true);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempEmail(event.target.value);
    };

    const handleEmailSave = () => {
        console.log('Email updated to:', tempEmail);
        setEmail(tempEmail);
        setIsEditingEmail(false);
    };

    const handleEmailCancel = () => {
        setTempEmail(email);
        setIsEditingEmail(false);
    };

    return (
        <>
            {/* Information Section */}
            <div className="p-6 bg-base-100 rounded-md shadow-md mb-6">
                <h3 className="text-2xl font-semibold mb-4">Information</h3>
                {/* Table-like container inside the card */}
                <div className="overflow-hidden rounded-md border border-gray-200 w-full mx-auto">
                    {/* Name Field */}
                    <div className="flex items-center px-4 py-3 border-b border-gray-200 last:border-b-0">
                        {/* Icon */}
                        <div className="w-12 flex-shrink-0 flex justify-center">
                            <span className="text-2xl">&#128100;</span>
                        </div>
                        {/* Label */}
                        <div className="w-32 flex-shrink-0">
                            <p className="text-gray-700">Name</p>
                        </div>
                        {/* Value/Input */}
                        <div className="flex-1">
                            {isEditingName ? (
                                <div className="flex items-center">
                                    <input
                                        type="text"
                                        placeholder="Enter your name"
                                        className="input input-bordered w-full mr-2"
                                        value={tempName}
                                        onChange={handleNameChange}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') handleNameSave();
                                        }}
                                        onBlur={handleNameCancel}
                                        autoFocus
                                        maxLength={50}
                                    />
                                </div>
                            ) : (
                                <span>{name}</span>
                            )}
                        </div>
                        {/* Edit/Save button */}
                        <div className="w-16 flex-shrink-0 flex justify-end">
                            {isEditingName ? (
                                <button className="btn btn-primary btn-sm" onClick={handleNameSave}>
                                    Save
                                </button>
                            ) : (
                                <FaEdit className="text-gray-500 cursor-pointer" onClick={handleNameEdit} />
                            )}
                        </div>
                    </div>

                    {/* Email Field */}
                    <div className="flex items-center px-4 py-3 last:border-b-0">
                        {/* Icon */}
                        <div className="w-12 flex-shrink-0 flex justify-center">
                            <span className="text-2xl">&#9993;</span>
                        </div>
                        {/* Label */}
                        <div className="w-32 flex-shrink-0">
                            <p className="text-gray-700">Email</p>
                        </div>
                        {/* Value/Input */}
                        <div className="flex-1">
                            {isEditingEmail ? (
                                <div className="flex items-center">
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        className="input input-bordered w-full mr-2"
                                        value={tempEmail}
                                        onChange={handleEmailChange}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') handleEmailSave();
                                        }}
                                        onBlur={handleEmailCancel}
                                        autoFocus
                                    />
                                </div>
                            ) : (
                                <span>{email}</span>
                            )}
                        </div>
                        {/* Edit/Save button */}
                        <div className="w-16 flex-shrink-0 flex justify-end">
                            {isEditingEmail ? (
                                <button className="btn btn-primary btn-sm" onClick={handleEmailSave}>
                                    Save
                                </button>
                            ) : (
                                <FaEdit className="text-gray-500 cursor-pointer" onClick={handleEmailEdit} />
                            )}
                        </div>
                    </div>
                </div>
            </div>


            {/* Update Password Section */}
            <div className="p-6 bg-base-100 rounded-md shadow-md mb-6">
                <h3 className="text-2xl font-semibold mb-4">Update Password</h3>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-gray-700">New password</label>
                        <input
                            type="password"
                            placeholder="New password"
                            className="input input-bordered w-full max-w-md"
                            value={newPassword}
                            onChange={handlePasswordChange}
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Confirm new password</label>
                        <input
                            type="password"
                            placeholder="Confirm new password"
                            className="input input-bordered w-full max-w-md"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Update Password
                    </button>
                </form>
            </div>

            {/* Security Section */}
            <div className="p-6 bg-base-100 rounded-md shadow-md mb-6">
                <h3 className="text-2xl font-semibold mb-4">Security</h3>
                <div>
                    <h4 className="text-lg font-semibold mb-2">Protect your account</h4>
                    <p className="text-gray-600 mb-4">
                        You'll be signed out of all devices, including the one you’re using now.
                    </p>
                    <button className="btn btn-outline">
                        Sign out of all devices
                    </button>
                </div>
            </div>

            {/* Danger Zone Section */}
            <div className="p-6 bg-base-100 rounded-md shadow-md mb-6">
                <h3 className="text-2xl font-semibold mb-4 text-red-600">
                    Danger Zone
                </h3>
                <div>
                    <h4 className="text-lg font-semibold mb-2">Delete your account</h4>
                    <p className="text-gray-600 mb-4">
                        Do you want to downgrade instead?{' '}
                        <a href="#" className="text-blue-600">
                            Manage subscriptions.
                        </a>
                    </p>
                    <button className="btn btn-error">Delete account</button>
                </div>
            </div>

            {/* Help Section */}
            <div className="text-center">
                <p className="text-gray-600">Need help? Let's get in touch.</p>
            </div>
        </>
    );
};

export default GeneralTab;
