import React from 'react';
import { Outlet } from 'react-router-dom';

const Inbox: React.FC = () => {
    return (
        <div className="p-5 bg-white rounded-lg shadow w-full overflow-x-hidden">
            <Outlet />
        </div>
    );
};

export default Inbox;
