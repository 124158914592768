import React from 'react';
import { useLocation } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import NavItem from '../NavItem/NavItem';
import { navigationLinks } from '../NavItem/navigationLinks';
import BackToInboxesButton from './BackToInboxesButton/BackToInboxesButton';

interface SidebarProps {
    isCollapsed: boolean;
    toggleCollapse: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isCollapsed, toggleCollapse }) => {
    const location = useLocation();
    const pathname = location.pathname;
    const isInInboxView = location.pathname.startsWith('/dashboard/inbox/');

    // Extract the inbox ID from the pathname
    let inboxId: string | null = null;
    if (isInInboxView) {
        // Match /dashboard/inbox/<id> or /dashboard/inbox/<id>/...
        const match = pathname.match(/^\/dashboard\/inbox\/([^\/]+)/);
        inboxId = match ? match[1] : null;
    }

    return (
        <div
            className={`bg-base-200 ${isCollapsed ? 'w-16' : 'w-56'
                } transition-width duration-300 h-full relative hidden md:flex flex-col`}
        >
            {/* Collapse Button */}
            <button
                onClick={toggleCollapse}
                className="absolute -right-3 top-1/2 transform -translate-y-1/2 bg-base-100 rounded-full p-1 shadow-md"
            >
                {isCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
            </button>

            {/* Main Content */}
            <div className="flex-grow">
                {/* Logo */}
                <div className="flex flex-col items-center justify-center h-16 border-b border-gray-300">
                    {!isCollapsed && <h1 className="text-xl font-bold">ParseTrue</h1>}
                    {isCollapsed && <h1 className="text-xl font-bold">PT</h1>}
                </div>

                {/* Navigation Items */}
                <nav className="mt-2">
                    <ul>
                        {!isCollapsed && isInInboxView && (
                            <li className="pb-1 mx-4">
                                {/* TODO: patch up */}
                                <BackToInboxesButton inboxName="Your Inbox Name" email="inbox.this.isverylong@example.com"/>
                            </li>
                        )}
                        {navigationLinks
                            .filter((link) => link.showInSidebar)
                            .filter((link) => {
                                if (isInInboxView) return link.context === 'inbox';
                                return link.context === 'default';
                            })
                            .map((link) => {
                                // Replace :id with inboxId
                                let to = link.to;
                                if (isInInboxView && inboxId && link.context === 'inbox') {
                                    to = link.to.replace(':id', inboxId);
                                }

                                // Skip rendering if inboxId is null
                                if (link.context === 'inbox' && !inboxId) {
                                    return null;
                                }

                                return (
                                    <NavItem
                                        key={to}
                                        to={to}
                                        label={link.label}
                                        icon={link.icon}
                                        isCollapsed={isCollapsed}
                                        className="mb-2"
                                    />
                                );
                            })}
                    </ul>
                </nav>
            </div>

            {/* Footer */}
            <div className="p-4 text-center">
                {isCollapsed ? '© 2024' : '© 2024 ParseTrue.com'}
            </div>
        </div>
    );
};

export default Sidebar;

