import React, { useState } from 'react';
import { FaFileInvoice } from 'react-icons/fa';

interface Invoice {
    id: number;
    name: string;
    date: string;
    url: string;
}

const InvoicesUsageTab: React.FC = () => {
    const invoices: Invoice[] = [
        // Add more invoices as needed
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const itemsPerPage = 10;

    const filteredInvoices = invoices.filter(invoice =>
        invoice.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredInvoices.length / itemsPerPage);
    const displayedInvoices = filteredInvoices.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset to first page on search
    };

    const formatDate = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <div className="flex flex-col items-center w-full h-full my-8">
            {invoices.length > 0 ? (
                <>
                    <input
                        type="text"
                        placeholder="Search invoices..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="input input-bordered mb-4 w-full max-w-lg"
                    />
                    <div className="w-full max-w-lg space-y-4">
                        {displayedInvoices.map(invoice => (
                            <a
                                key={invoice.id}
                                href={invoice.url}
                                className="flex justify-between p-4 border border-gray-300 rounded-lg bg-white hover:bg-gray-100 transition-colors"
                            >
                                <div className="font-bold">{invoice.name}</div>
                                <div className="text-right">{formatDate(invoice.date)}</div>
                            </a>
                        ))}
                    </div>
                    {totalPages > 1 && (
                        <div className="mt-4 flex space-x-2">
                            {Array.from({ length: totalPages }, (_, index) => (
                                <button
                                    key={index}
                                    onClick={() => handlePageChange(index + 1)}
                                    className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-outline'} w-10`}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    )}
                </>
            ) : (
                <div className="flex flex-col items-center p-8 border border-gray-300 rounded-lg bg-white max-w-lg text-center">
                    <FaFileInvoice className="text-6xl text-red-500 mb-4" />
                    <h2 className="text-2xl font-bold mb-2">No Invoices</h2>
                    <p className="text-gray-600">You currently have no invoices.</p>
                </div>
            )}
        </div>
    );
}

export default InvoicesUsageTab;
