import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../../../../components/Breadcrumb/Breadcrumb';
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone';

interface Document {
    id: string;
    status: string;
    name: string;
    sender: string;
    parsed: string;
    received: string;
}

const initialDocuments: Document[] = [
    {
        id: "oosiids",
        status: "completed",
        name: "This is an invoice",
        sender: "john@gmial.com",
        parsed: "Sept 6, 15:43",
        received: "Sept 6, 13:42"
    },
    {
        id: "oosiids",
        status: "completed",
        name: "This is an invoice",
        sender: "john@gmial.com",
        parsed: "Sept 6, 15:43",
        received: "Sept 6, 13:42"
    },
    {
        id: "oosiids",
        status: "completed",
        name: "This is an invoice",
        sender: "john@gmial.com",
        parsed: "Sept 6, 15:43",
        received: "Sept 6, 13:42"
    },
    {
        id: "oosiids",
        status: "completed",
        name: "This is an invoice",
        sender: "john@gmial.com",
        parsed: "Sept 6, 15:43",
        received: "Sept 6, 13:42"
    },
];

const InboxHome: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [documents, setDocuments] = useState<Document[]>(initialDocuments); // Todo: wire up api
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
    const [showDropzone, setShowDropzone] = useState<boolean>(false);

    const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);   // for delete of documents
    const [showConfirmReprocessModal, setShowConfirmReprocessModal] = useState<boolean>(false);

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelectedDocuments(documents.map((_, index) => index));
        } else {
            setSelectedDocuments([]);
        }
    };

    const handleCheckboxChange = (index: number, isChecked: boolean) => {
        setSelectedDocuments((prev) => {
            if (isChecked) {
                return [...prev, index];
            } else {
                return prev.filter(i => i !== index);
            }
        });
    };

    const handleDelete = () => {
        const newDocuments = documents.filter((_, index) => !selectedDocuments.includes(index));
        setDocuments(newDocuments);
        setSelectedDocuments([]);
        setShowConfirmModal(false);
        // TODO: Add logic to delete the documents if necessary
    };

    // Handler for re-process confirmation
    const handleReprocess = () => {
        setSelectedDocuments([]);
        setShowConfirmReprocessModal(false);
        // TODO: Add logic to re-process the documents if necessary
    };

    const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent) => {
        setUploadedFiles(acceptedFiles);
        setRejectedFiles(rejectedFiles);

        if (rejectedFiles.length > 0) {
            setRejectedFiles(rejectedFiles);
            setShowModal(true);
        }

        console.log("Accepted Files: ", acceptedFiles);
        console.log("Rejected Files: ", rejectedFiles);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            "application/pdf": [".pdf"],
            "message/rfc822": [".eml"],
            "text/html": [".html"],
            "text/plain": [".txt", ".md"],
            "image/jpeg": [".jpg", ".jpeg"],
            "image/png": [".png"],
            "image/bmp": [".bmp"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
            "application/msword": [".doc"],
        }
    });

    return (
        <>
            {/* Breadcrumb */}
            <Breadcrumb pageName='Inbox' />

            <div className="flex justify-between items-center mb-5">
                <h3 className="text-2xl font-semibold m-0">Documents</h3>
                {documents.length > 0 ? (
                    <button className="btn btn-primary font-bold" onClick={() => setShowDropzone(!showDropzone)}>
                        + Import documents
                    </button>
                ) : <></>}
            </div>

            {selectedDocuments.length > 0 && (
                <div className="flex space-x-3 mb-3">
                    <button
                        className="btn btn-danger flex items-center justify-center"
                        onClick={() => setShowConfirmModal(true)}
                    >
                        <span className="mr-2">🗑️</span> Delete {selectedDocuments.length} Documents
                    </button>
                    <button
                        className="btn btn-primary flex items-center justify-center"
                        onClick={() => setShowConfirmReprocessModal(true)}
                    >
                        <span className="mr-2">⏩</span> Re-process {selectedDocuments.length} Documents
                    </button>
                </div>
            )}

            {documents.length > 0 ? (
                <>
                    {showDropzone && (
                        <div className="border-2 border-dashed border-gray-300 p-6 rounded-lg mb-5 cursor-pointer" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p className="text-gray-600 text-center">Drag and drop files here, or click to select files.</p>
                            <p className="text-sm text-gray-500 mt-2 text-center">Accepted formats: pdf, eml, html, txt, jpg, png, bmp, docx, md</p>
                        </div>
                    )}

                    <table className="table w-full">
                        <thead className="bg-gray-100">
                            <tr>
                                <th>
                                    <label className="flex items-center justify-left w-full h-full cursor-pointer">
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            onChange={handleSelectAll}
                                            checked={selectedDocuments.length === documents.length}
                                        />
                                    </label>
                                </th>
                                <th className="text-left text-sm font-semibold text-gray-700">STATUS</th>
                                <th className="text-left text-sm font-semibold text-gray-700">NAME</th>
                                <th className="text-left text-sm font-semibold text-gray-700">SENDER</th>
                                <th className="text-left text-sm font-semibold text-gray-700">PARSED</th>
                                <th className="text-left text-sm font-semibold text-gray-700">RECEIVED</th>
                            </tr>
                        </thead>
                        <tbody>
                            {documents.map((doc, index) => (
                                <tr
                                    key={index}
                                    className={`hover:cursor-pointer ${selectedDocuments.includes(index) ? "bg-gray-200" : ""}`}
                                    onClick={(e) => {
                                        if (!(e.target as HTMLElement).matches('input[type="checkbox"]')) {
                                            navigate(`/dashboard/inbox/${id}/doc/${doc.id}`);
                                        }
                                    }}
                                >
                                    <td>
                                        <label className="flex items-center justify-left w-full h-full cursor-pointer">
                                            <input
                                                type="checkbox"
                                                className="checkbox"
                                                checked={selectedDocuments.includes(index)}
                                                onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                                            />
                                        </label>
                                    </td>
                                    <td>
                                        <span className="inline-block px-2 py-1 bg-green-100 text-green-800 rounded text-xs font-bold uppercase">
                                            {doc.status}
                                        </span>
                                    </td>
                                    <td>{doc.name}</td>
                                    <td>{doc.sender}</td>
                                    <td>{doc.parsed}</td>
                                    <td>{doc.received}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <div className="text-center">
                    <h2 className="text-xl font-bold mb-3">Upload or Send your first document to the following inbox</h2>
                    <div {...getRootProps()} className="border-2 border-dashed border-gray-300 p-6 rounded-lg cursor-pointer">
                        <input {...getInputProps()} />
                        <p className="text-gray-600">Drag and drop files here, or click to select files.</p>
                        <p className="text-sm text-gray-500 mt-2">Accepted formats: pdf, eml, html, txt, jpg, png, bmp, docx, md</p>
                    </div>
                    <p className="mt-4 text-sm text-gray-600">Drag and drop your document into the upload box above</p>
                    <div className="text-2xl font-bold mt-4">Or</div>
                    <p className="mt-4 text-gray-600">Wait for your first document to arrive into this inbox</p>
                    <p className="text-sm text-gray-500 mt-2">Receiving email typically takes around 2 - 5 minutes. Contact us if you face any problem. You may refresh this page to see results.</p>
                </div>
            )}

            {documents.length > 0 && (
                <div className="mt-5 text-sm text-gray-600 text-right">
                    <span>Showing 1 to {documents.length} of {documents.length} documents</span>
                </div>
            )}

            {
                showModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg">
                            <h2 className="text-xl font-bold mb-4">File Rejection</h2>
                            <p>The following files have been rejected:</p>
                            <ul className="list-disc list-inside mb-4">
                                {rejectedFiles.map(({ file }, index) => (
                                    <li key={index}>
                                        {file.name}
                                    </li>
                                ))}
                            </ul>
                            <p className="text-red-600 mb-4">Accepted formats: pdf, eml, html, txt, jpg, png, bmp, docx, md</p>
                            <button className="btn btn-primary" onClick={() => { setShowModal(false); setRejectedFiles([]); }}>
                                Close
                            </button>
                        </div>
                    </div>
                )
            }

            {showConfirmModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
                        <p>Are you sure you want to delete {selectedDocuments.length} documents?</p>
                        <div className="flex space-x-3 mt-4">
                            <button className="btn btn-danger" onClick={handleDelete}>
                                Confirm
                            </button>
                            <button className="btn btn-secondary" onClick={() => setShowConfirmModal(false)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showConfirmReprocessModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-xl font-bold mb-4">Confirm Re-process</h2>
                        <p>Re-processing {selectedDocuments.length} documents will <b>cost additional {selectedDocuments.length} credits</b>. Are you sure you want to continue?</p>
                        <div className="flex space-x-3 mt-4">
                            <button className="btn btn-primary" onClick={handleReprocess}>
                                Confirm
                            </button>
                            <button className="btn btn-secondary" onClick={() => setShowConfirmReprocessModal(false)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
};

export default InboxHome;