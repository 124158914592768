import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ReactJson from 'react-json-view';
import { FaArrowLeft, FaArrowRight, FaDownload, FaSync, FaCog, FaTrash } from 'react-icons/fa';
import Timeline from './Event/Event';
import Template from './Template/Template';
import ParsedData from './Parsed/Parsed';

const Document: React.FC = () => {
    const { id, docId } = useParams<{ id: string; docId: string }>();
    const [emlContent, setEmlContent] = useState<string | null>(null);
    const [creditUsed, setCreditUsed] = useState<number>(1);
    const [activeTab, setActiveTab] = useState<string>('parsed');
    const [leftWidth, setLeftWidth] = useState<number>(60); // Initial width percentage for the left panel

    // Load the .eml file
    useEffect(() => {
        fetch('/assets/test/test_email_html.html')
            .then((res) => res.text())
            .then((data) => setEmlContent(data))
            .catch((err) => console.error('Error loading .html file:', err));
    }, []);

    // Dynamically adjust the slider for left and right
    const handleMouseDown = (e: React.MouseEvent) => {
        const startX = e.clientX;
        const startWidth = leftWidth;

        const onMouseMove = (e: MouseEvent) => {
            const newWidth = startWidth + ((e.clientX - startX) / window.innerWidth) * 100;
            setLeftWidth(Math.max(20, Math.min(80, newWidth))); // Ensure the width stays between 20% and 80%
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    const testData = {
        "bill_to_address": "John Doe 456 Elm Street, Los Angeles, CA 90001",
        "name": "John Doe",
        "order": [
            {
                "description": "Men's Running Shoes, Size 10, Black",
                "item_name": "Running Shoes",
                "pricing": "100.00",
                "quantity": "1",
                "total_price": "100.00",
                "qua": "1",
                "control_amount": "2003"
            },
            {
                "description": "Estimated delivery in 5-7 business days",
                "item_name": "Shipping",
                "pricing": "5.00",
                "quantity": "1",
                "total_price": "5.00",
                "qua": "1",
                "control_amount": "2003"
            }
        ],
        "order2": [
            {
                "description": "Men's Running Shoes, Size 10, Black",
                "item_name": "Running Shoes",
                "pricing": "100.00",
                "quantity": "1",
                "total_price": "100.00",
                "qua": "1",
                "control_amount": "2003"
            },
            {
                "description": "Estimated delivery in 5-7 business days",
                "item_name": "Shipping",
                "pricing": "5.00",
                "quantity": "1",
                "total_price": "5.00",
                "qua": "1",
                "control_amount": "2003"
            }
        ],
        "order3": [
            {
                "description": "Men's Running Shoes, Size 10, Black",
                "item_name": "Running Shoes",
                "pricing": "100.00",
                "quantity": "1",
                "total_price": "100.00",
                "qua": "1",
                "control_amount": "2003"
            },
            {
                "description": "Estimated delivery in 5-7 business days",
                "item_name": "Shipping",
                "pricing": "5.00",
                "quantity": "1",
                "total_price": "5.00",
                "qua": "1",
                "control_amount": "2003"
            }
        ],
        "order4": [
            {
                "description": "Men's Running Shoes, Size 10, Black",
                "item_name": "Running Shoes",
                "pricing": "100.00",
                "quantity": "1",
                "total_price": "100.00",
                "qua": "1",
                "control_amount": "2003"
            },
            {
                "description": "Estimated delivery in 5-7 business days",
                "item_name": "Shipping",
                "pricing": "5.00",
                "quantity": "1",
                "total_price": "5.00",
                "qua": "1",
                "control_amount": "2003"
            }
        ],
        "order_amt": "113.40",
        "shipping_address": "John Doe 456 Elm Street, Los Angeles, CA 90001",
        "tax": "8.40",
        "order_amt2": "113.40",
        "shipping_address2": "John Doe 456 Elm Street, Los Angeles, CA 90001",
        "tax2": "8.40",
        "order_amt3": "113.40",
        "shipping_address3": "John Doe 456 Elm Street, Los Angeles, CA 90001",
        "tax3": "8.40"
    };

    return (
        <div className="p-4 bg-base-200">
            <div className="mb-4">
                {/* Breadcrumb */}
                <ul className="text-md breadcrumbs flex items-center">
                    <li className="font-medium">
                        <Link to="/dashboard/inboxes" className="text-blue-500 hover:text-blue-700">
                            Inboxes
                        </Link>
                    </li>
                    <li className="mx-2"> {/* Adjust spacing around separator */}
                        <span className="text-gray-500">/</span> {/* Standalone separator */}
                    </li>
                    <li className="font-medium">
                        <Link to={`/dashboard/inbox/${id}`} className="text-blue-500 hover:text-blue-700">
                            Inbox
                        </Link>
                    </li>
                    <li className="mx-2"> {/* Adjust spacing around separator */}
                        <span className="text-gray-500">/</span> {/* Standalone separator */}
                    </li>
                    <li className="font-medium">
                        Document
                    </li>
                </ul>
            </div>

            <div className="flex">
                {/* Left-hand side - Document Viewer */}
                <div className="bg-white shadow-md rounded-lg p-4" style={{ width: `${leftWidth}%` }}>
                    <div className="flex justify-between items-center border-b pb-2 mb-4">
                        <h6 className="text-md font-semibold">{docId ? `Document ID: ${docId}` : 'Sample document'}</h6>
                        <div className="flex gap-2">
                            <button className="btn btn-outline btn-sm" aria-label="Previous document">
                                <FaArrowLeft />
                            </button>
                            <button className="btn btn-outline btn-sm" aria-label="Next document">
                                <FaArrowRight />
                            </button>
                        </div>
                    </div>
                    <div className="border p-4 rounded-lg overflow-auto min-h-[842px] max-h-[842px] overflow-x-auto">
                        {emlContent ? (
                            <div dangerouslySetInnerHTML={{ __html: emlContent }} />
                        ) : (
                            <p>Loading .eml file...</p>
                        )}
                    </div>
                </div>

                {/* Resizer */}
                <div
                    className="w-2 bg-gray-300 cursor-col-resize"
                    onMouseDown={handleMouseDown}
                ></div>

                {/* Right-hand side - Extracted Data */}
                <div className="bg-white shadow-md rounded-lg p-4" style={{ width: `${100 - leftWidth}%` }}>
                    <div className="flex justify-between items-center mb-4">
                        <span className="text-sm font-bold">Credit Used: {creditUsed}</span>
                        <div className="flex gap-2">
                            <button className="btn btn-outline btn-sm" aria-label="Download">
                                <FaDownload />
                            </button>
                            <button className="btn btn-outline btn-sm" aria-label="Reprocess">
                                <FaSync />
                            </button>
                            <button className="btn btn-outline btn-sm" aria-label="PostProcessing">
                                <FaCog />
                            </button>
                            <button className="btn btn-outline btn-sm text-red-500" aria-label="Delete">
                                <FaTrash />
                            </button>
                        </div>
                    </div>

                    <div>
                        <div className="tabs tabs-boxed mb-2">
                            <a
                                className={`tab ${activeTab === 'parsed' ? 'tab-active' : ''}`}
                                onClick={() => setActiveTab('parsed')}
                            >
                                Parsed
                            </a>
                            <a
                                className={`tab ${activeTab === 'output' ? 'tab-active' : ''}`}
                                onClick={() => setActiveTab('output')}
                            >
                                Output
                            </a>
                            <a
                                className={`tab ${activeTab === 'event' ? 'tab-active' : ''}`}
                                onClick={() => setActiveTab('event')}
                            >
                                Event
                            </a>
                            <a
                                className={`tab ${activeTab === 'schema' ? 'tab-active' : ''}`}
                                onClick={() => setActiveTab('schema')}
                            >
                                Template
                            </a>
                        </div>

                        <div className="overflow-auto">
                            {activeTab === 'parsed' && (
                                <ParsedData activeTab={activeTab} data={testData} />
                            )}
                            {activeTab === 'output' && (
                                <>
                                    <div className="max-w-4xl mx-auto p-3 overflow-x-auto min-w-[530px]">
                                        {/* Header */}
                                        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                                            <h5 className="font-bold">Output Data</h5>
                                        </div>
                                        <ReactJson
                                            src={testData}
                                            collapsed={1}
                                            enableClipboard={true}
                                            displayDataTypes={false}
                                            displayObjectSize={true}
                                            theme="monokai"
                                        />
                                    </div>
                                </>
                            )}
                            {activeTab === 'event' && (
                                <>
                                    <Timeline />
                                </>
                            )}
                            {activeTab === 'schema' && (
                                <>
                                    <Template />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Document;
