import React, { useState } from 'react';

import GeneralTab from './Tabs/General/GeneralTab';
import AdditionalFieldsTab from './Tabs/AdditionalFields/AdditionalFieldsTab';
import AdvancedTab from './Tabs/Advanced/AdvancedTab';

import Breadcrumb from '../../../../../components/Breadcrumb/Breadcrumb';

const InboxSetting: React.FC = () => {
    const [activeTab, setActiveTab] = useState('General');

    const General = 'General';
    const AdditionalFields = 'Additional Fields';
    const Advanced = 'Advanced';

    const handleTabClick = (tabName: string) => {
        setActiveTab(tabName);
    };

    return (
        <>
            <>  {/* Changed `mx-auto` to left-align */}
                {/* Breadcrumb */}
                <Breadcrumb pageName='Settings' />

                <div className="border-b border-gray-300 mb-4">
                    <ul className="flex gap-6">
                        <li
                            className={`cursor-pointer py-2 px-4 transition-colors duration-300 ${activeTab === General ? 'border-b-4 border-primary font-bold text-primary' : 'hover:text-primary'}`}
                            onClick={() => handleTabClick(General)}
                        >
                            General
                        </li>
                        <li
                            className={`cursor-pointer py-2 px-4 transition-colors duration-300 ${activeTab === AdditionalFields ? 'border-b-4 border-primary font-bold text-primary' : 'hover:text-primary'}`}
                            onClick={() => handleTabClick(AdditionalFields)}
                        >
                            Additional Fields
                        </li>
                        <li
                            className={`cursor-pointer py-2 px-4 transition-colors duration-300 ${activeTab === Advanced ? 'border-b-4 border-primary font-bold text-primary' : 'hover:text-primary'}`}
                            onClick={() => handleTabClick(Advanced)}
                        >
                            Advanced
                        </li>
                    </ul>
                </div>
                {activeTab === General && <GeneralTab />}
                {activeTab === AdditionalFields && <AdditionalFieldsTab />}
                {activeTab === Advanced && <AdvancedTab />}
            </>
        </>
    );
};

export default InboxSetting;