import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const plans = [
    {
        name: 'Starter',
        price: '$32.50',
        discount: '17% off',
        features: [
            '100 credits per month',
            'Unlimited inboxes',
            '30 days documents retention'
        ],
        buttonLabel: 'Upgrade to Starter',
        icon: '🟢',
    },
    {
        name: 'Growth',
        price: '$49.17',
        discount: '17% off',
        features: [
            '500 credits per month',
            'Unlimited inboxes',
            '90 days documents retention'
        ],
        buttonLabel: 'Upgrade to Growth',
        icon: '🟡',
    },
    {
        name: 'Business',
        price: '$149.17',
        discount: '17% off',
        features: [
            '2,000 credits per month',
            'Unlimited inboxes',
            '90 days documents retention',
            'Invite team members (unlimited)'
        ],
        buttonLabel: 'Upgrade to Business',
        icon: '🔵',
        isPopular: true, // Additional property to mark it as the most popular plan
    },
    {
        name: 'Premium',
        price: '$249.17',
        discount: '17% off',
        features: [
            '5,000 credits per month',
            'Unlimited inboxes',
            '180 days documents retention',
            'Invite team members (unlimited)'
        ],
        buttonLabel: 'Upgrade to Premium',
        icon: '🔴',
    }
];

const currentPlan = {
    name: 'Current Plan',
    price: '$49.17',
    features: [
        '500 credits per month',
        'Unlimited inboxes',
        '90 days documents retention'
    ],
    nextBillingCycle: 'Next billing cycle: September 30, 2024',
    buttonLabel: 'Manage Plan',
    icon: '🔹',
    isCurrentPlan: true, 
};

const SubscriptionTab: React.FC = () => {
    return (
        <div className="p-5">
            <h2 className="text-center text-2xl font-bold mb-4">Choose your plan</h2>
            <p className="text-center text-gray-600 mb-8">
                You can adjust your subscription whenever you like. Changes, whether upgrading or downgrading, are applied immediately. For more information, please refer to our FAQ.
            </p>
            
            <div className="flex flex-col gap-6">
                {plans.map((plan, index) => (
                    <div 
                        key={index}
                        className={`card border-2 rounded-lg shadow-md p-5 ${plan.isPopular ? 'border-primary' : 'border-gray-200'} relative`}
                    >
                        {plan.isPopular && (
                            <span className="badge badge-primary absolute top-2 left-3">Most popular</span>
                        )}
                        <div className="flex justify-between items-start mb-5">
                            <div className="flex items-center mt-1">
                                <span className="text-4xl mr-3">{plan.icon}</span>
                                <div>
                                    <h5 className="text-xl font-bold pt-1">{plan.name}</h5>
                                    {plan.discount && <p className="text-gray-600 text-sm">{plan.discount}</p>}
                                </div>
                            </div>
                            <h3 className="text-3xl font-bold">{plan.price}</h3>
                        </div>
                        <p className="text-sm text-gray-500 mb-5">per month, billed annually</p>
                        <ul className="list-none mb-5">
                            {plan.features.map((feature, i) => (
                                <li key={i} className="flex items-center mb-2">
                                    <FaCheckCircle className="text-green-500 mr-2" /> {feature}
                                </li>
                            ))}
                        </ul>
                        <button className="btn btn-primary w-full">{plan.buttonLabel}</button>
                    </div>
                ))}

                {/* Current Plan Card */}
                <div className="card border-2 rounded-lg shadow-md p-5 bg-gray-100 border-gray-500 relative">
                    <span className="badge badge-secondary absolute top-2 left-3">Current Plan</span>
                    <div className="flex justify-between items-start mb-5">
                        <div className="flex items-center mt-1">
                            <span className="text-4xl mr-1">{currentPlan.icon}</span>
                            <div>
                                <h5 className="text-xl font-bold">{currentPlan.name}</h5>
                            </div>
                        </div>
                        <h3 className="text-3xl font-bold">{currentPlan.price}</h3>
                    </div>
                    <p className="text-sm text-gray-500 mb-5">{currentPlan.nextBillingCycle}</p>
                    <ul className="list-none mb-5">
                        {currentPlan.features.map((feature, i) => (
                            <li key={i} className="flex items-center mb-2">
                                <FaCheckCircle className="text-green-500 mr-2" /> {feature}
                            </li>
                        ))}
                    </ul>
                    <button className="btn btn-secondary w-full">{currentPlan.buttonLabel}</button>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionTab;
