import React, { useState } from 'react';
import { FaPlus, FaSave, FaEdit, FaTrashAlt } from 'react-icons/fa';
import FieldForm from './FieldForm';
import { v4 as uuidv4 } from 'uuid';

interface Field {
    uniqueId: string;
    label: string;
    description: string;
    subfields?: { label: string; description: string }[];
}

const Template: React.FC = () => {
    const [fields, setFields] = useState<Field[]>([
        {
            uniqueId: '1',
            label: 'order_amt',
            description: 'The total order amount',
        },
        {
            uniqueId: '2',
            label: 'shipping_address',
            description: 'The shipping address of the invoice',
        },
        {
            uniqueId: '3',
            label: 'name',
            description: 'The name of the recipient',
        },
        {
            uniqueId: '4',
            label: 'order',
            description: 'Order items',
            subfields: [
                { label: 'item_name', description: 'The name of the item' },
                { label: 'description', description: 'Description of the item' },
                { label: 'quantity', description: 'Number of items' },
                { label: 'pricing', description: 'The price of the item' },
                { label: 'total_price', description: 'The total price of the item' },
            ],
        },
        {
            uniqueId: '5',
            label: 'tax',
            description: 'The tax of the item',
        },
    ]);

    const [editingField, setEditingField] = useState<Field | null>(null);
    const [deletingField, setDeletingField] = useState<Field | null>(null); // State for the field to be deleted
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleFieldSave = (updatedField: Field) => {
        if (updatedField.uniqueId) {
            // Update existing field
            setFields((prevFields) =>
                prevFields.map((field) =>
                    field.uniqueId === updatedField.uniqueId ? updatedField : field
                )
            );
        } else {
            // Add new field
            // Assign a new uniqueId
            const newUniqueId = uuidv4();
            updatedField.uniqueId = newUniqueId;
            setFields((prevFields) => [...prevFields, updatedField]);
        }
        setEditingField(null); // Exit edit mode after saving
    };

    const handleDeleteField = () => {
        if (deletingField) {
            setFields((prevFields) => prevFields.filter((field) => field.uniqueId !== deletingField.uniqueId));
        }
        setIsModalOpen(false);
        setDeletingField(null);
    };

    const openDeleteModal = (field: Field) => {
        setDeletingField(field);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setDeletingField(null);
    };

    // Conditional rendering based on editingField
    if (editingField) {
        return (
            <FieldForm
                initialField={editingField}
                onSave={handleFieldSave}
                onCancel={() => setEditingField(null)}
            />
        );
    }

    return (
        <div className="max-w-4xl mx-auto p-3 overflow-x-auto min-w-[530px]">
            {/* Header */}
            <div className="flex flex-col md:flex-row justify-between items-center mb-4">
                <h5 className="font-bold">Data Extraction Template</h5>
                <span className="badge badge-warning">Unsaved changes</span>
            </div>
            <p className="text-gray-600 mb-6">
                The Data Extraction Template is configured at the inbox level, ensuring that it applies universally to all documents within the inbox. Once the template is updated, select the 'Reprocess' button in the left pane to reanalyze the document. Within the inbox settings, you have the option to add document metadata fields, such as the email subject, file name, timestamp, and other details. <a href="#" className="text-blue-500 hover:underline">Learn more.</a>
            </p>
            <div className="flex flex-col md:flex-row justify-between items-center mb-6">
                {/* Left Side: Last Updated and Unsaved Changes */}
                <div className="flex items-center mb-4 md:mb-0 space-x-4">
                    <button className="btn btn-sm btn-success">
                        <FaSave className="mr-1" /> Save Schema
                    </button>
                </div>
                {/* Right Side: Add Field Buttons */}
                <div className="flex space-x-2">
                    <button
                        className="btn btn-sm btn-primary"
                        onClick={() => setEditingField({ uniqueId: '', label: '', description: '' })}
                    >
                        <FaPlus className="mr-2" /> Add Text Field
                    </button>
                    <button
                        className="btn btn-sm btn-primary"
                        onClick={() => setEditingField({
                            uniqueId: '',
                            label: '',
                            description: '',
                            subfields: [{
                                label: '',
                                description: ''
                            }]
                        })}
                    >
                        <FaPlus className="mr-2" /> Add List/Table Field
                    </button>
                </div>
            </div>

            {/* Fields List */}
            <div className="grid grid-cols-1 gap-4 mb-6">
                {fields.map((field, index) => (
                    <div
                        key={index}
                        className="bg-base-100 shadow-md rounded-md overflow-hidden"
                    >
                        <div className="flex justify-between items-center p-4 bg-gray-100">
                            <div>
                                <h2 className="text-lg font-semibold">{field.label}</h2>
                                <p className="text-sm text-gray-600">{field.description}</p>
                            </div>
                            <div className="flex space-x-2">
                                <button className="btn btn-sm btn-outline" onClick={() => setEditingField({
                                    uniqueId: field.uniqueId,
                                    label: field.label,
                                    description: field.description,
                                    subfields: field.subfields
                                })}>
                                    <FaEdit />
                                </button>
                                <button className="btn btn-sm btn-outline btn-error" onClick={() => openDeleteModal(field)}>
                                    <FaTrashAlt />
                                </button>
                            </div>
                        </div>
                        {field.subfields && field.subfields.length > 0 && (
                            <div className="p-4">
                                <h3 className="text-md font-semibold mb-2">Subfields:</h3>
                                <ul className="space-y-1">
                                    {field.subfields.map((subfield, idx) => (
                                        <li key={idx} className="pl-2 border-l-2 border-gray-300">
                                            <span className="font-medium">{subfield.label}</span>: {subfield.description}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* Footer */}
            <div className="flex flex-col md:flex-row justify-between items-center">
                <button className="btn btn-sm btn-success">
                    <FaSave className="mr-1" /> Save Schema
                </button>
                <div className="text-sm text-gray-500 mb-4 md:mb-0">
                    Last updated a month ago
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto">
                        <h3 className="text-xl font-bold mb-4">Delete Field</h3>
                        <p className="text-gray-600 mb-6">Are you sure you want to delete this field? This action cannot be undone.</p>
                        <div className="flex justify-end space-x-3">
                            <button className="btn btn-ghost" onClick={closeModal}>
                                Cancel
                            </button>
                            <button className="btn btn-error" onClick={handleDeleteField}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Template;
