import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEnvelope, FaCopy } from 'react-icons/fa';

interface BackToInboxesButtonProps {
    inboxName: string;
    email: string;
}

const BackToInboxesButton: React.FC<BackToInboxesButtonProps> = ({ inboxName, email }) => {
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);

    const handleBack = () => {
        navigate('/dashboard/inboxes');
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(email);
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000);
    };

    return (
        <div className="bg-base-100 shadow-md rounded-md flex flex-col items-center text-center w-full px-2 py-3 relative">
            {showAlert && (
                <div className="alert alert-success shadow-lg mb-4 flex justify-between items-center absolute left-4 transform translate-y-[-100%] transition-transform">
                    <div>
                        <span>Email copied to clipboard!</span>
                    </div>
                    <button
                        onClick={() => setShowAlert(false)}
                        className="btn btn-sm btn-ghost"
                        aria-label="Close"
                    >
                        ✕
                    </button>
                </div>
            )}

            <div className="mb-4 w-full flex flex-col items-center px-1">
                <div className="tooltip tooltip-bottom" data-tip="Inbox Name">
                    <span className="text-xl font-semibold truncate block w-full" style={{ maxWidth: 130 }}>
                        {inboxName}
                    </span>
                </div>
                <div className="tooltip tooltip-bottom mt-1" data-tip="Click to copy">
                    <button
                        onClick={handleCopy}
                        className="flex items-center text-gray-500 truncate w-full"
                        style={{ minWidth: '0' }}
                    >
                        <FaCopy className="mr-1 flex-shrink-0" />
                        <small className="truncate" style={{ maxWidth: 130 }}>{email}</small>
                    </button>
                </div>
            </div>
            <button onClick={handleBack} className="btn btn-primary">
                <FaEnvelope className="mr-2" />
                Back to Inboxes
            </button>
        </div>
    );
};

export default BackToInboxesButton;
