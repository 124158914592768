// navigationLinks.ts

import { IconType } from 'react-icons';
import { FaInbox, FaHammer, FaUserCog, FaBell, FaQuestionCircle, FaCog, FaFileCode, FaCode, FaCodeBranch } from 'react-icons/fa';

interface NavigationLink {
    to: string;
    label: string;
    icon: IconType;
    showInSidebar?: boolean;
    showInMobileMenu?: boolean;
    showInDesktopMenu?: boolean;
    context: 'default' | 'inbox';
}

export const navigationLinks: NavigationLink[] = [
    {
        to: '/dashboard/inboxes',
        label: 'Inboxes',
        icon: FaInbox,
        showInSidebar: true,
        showInMobileMenu: true,
        context: 'default',
    },
    {
        to: '/dashboard/parse-engine',
        label: 'Parse Engine',
        icon: FaHammer,
        showInSidebar: true,
        showInMobileMenu: true,
        context: 'default',
    },
    {
        to: '/dashboard/account',
        label: 'Account & Settings',
        icon: FaUserCog,
        showInSidebar: true,
        showInMobileMenu: true,
        context: 'default',
    },
    {
        to: '/notifications',
        label: 'Notifications',
        icon: FaBell,
        showInMobileMenu: true,
        showInDesktopMenu: true,
        context: 'default',
    },
    {
        to: '/help',
        label: 'Help Center',
        icon: FaQuestionCircle,
        showInMobileMenu: true,
        showInDesktopMenu: true,
        context: 'default',
    },
    {
        to: `/dashboard/inbox/:id`, // start of inbox view routes
        label: 'Inbox',
        icon: FaInbox,
        showInSidebar: true,
        showInMobileMenu: true,
        context: 'inbox',
    },
    {
        to: `/dashboard/inbox/:id/parsed-data`,
        label: 'Parsed Data',
        icon: FaFileCode,
        showInSidebar: true,
        showInMobileMenu: true,
        context: 'inbox',
    },
    {
        to: `/dashboard/inbox/:id/post-processing`,
        label: 'Post-processing',
        icon: FaCode,
        showInSidebar: true,
        showInMobileMenu: true,
        context: 'inbox',
    },
    {
        to: `/dashboard/inbox/:id/integrations`,
        label: 'Integrations',
        icon: FaCodeBranch,
        showInSidebar: true,
        showInMobileMenu: true,
        context: 'inbox',
    },
    {
        to: `/dashboard/inbox/:id/settings`,
        label: 'Inbox Settings',
        icon: FaCog,
        showInSidebar: true,
        showInMobileMenu: true,
        context: 'inbox',
    },
];