import React from 'react';

import { ReactComponent as GoogleIcon } from '../../assets/icons/google_icon.svg';
import { ReactComponent as MicrosoftIcon } from '../../assets/icons/microsoft_icon.svg';

const SignupPage: React.FC = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="flex flex-col md:flex-row w-full max-w-5xl shadow-lg rounded-lg md:min-h-[640px]">
                {/* Left Column */}
                <div className="hidden md:flex flex-col justify-center items-center bg-blue-600 text-white p-5 rounded-l-lg w-full md:w-1/2">
                    <h1 className="text-4xl font-bold">ParseTrue</h1>
                    <p className="mt-3 text-xl">Fast, Efficient and Productive</p>
                </div>

                {/* Right Column */}
                <div className="bg-white p-6 md:w-1/2 w-full rounded-r-lg flex flex-col justify-center">
                    <div className="max-w-md mx-auto">
                        <h2 className="text-center text-2xl font-semibold mb-2">Sign Up</h2>
                        <p className="text-center text-gray-500 mb-6">With social accounts</p>
                        <button className="w-full border border-gray-300 py-2 flex items-center justify-center space-x-2 rounded-md hover:bg-gray-100 mb-2">
                            <GoogleIcon className="w-5 h-5" />
                            <span>Sign up with Google</span>
                        </button>
                        <button className="w-full border border-gray-300 py-2 flex items-center justify-center space-x-2 rounded-md hover:bg-gray-100 mb-4">
                            <MicrosoftIcon className="w-5 h-5" />
                            <span>Sign up with Microsoft</span>
                        </button>
                        <div className="my-4 flex items-center">
                            <span className="border-t border-gray-300 flex-grow"></span>
                            <span className="mx-2 text-gray-500">Or continue with</span>
                            <span className="border-t border-gray-300 flex-grow"></span>
                        </div>
                        <form className="space-y-4">
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                            <input
                                type="email"
                                placeholder="Email"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <input
                                type="password"
                                placeholder="Password"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <input
                                type="password"
                                placeholder="Repeat Password"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    id="terms"
                                    className="mr-2"
                                />
                                <label htmlFor="terms" className="text-gray-700">I Accept the Terms</label>
                            </div>
                            <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-200">
                                Sign Up
                            </button>
                        </form>
                        <p className="text-center text-gray-500 mt-3">
                            Already have an Account? <a href="#signin" className="text-blue-600 hover:underline">Sign in</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignupPage;