import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

import { ReactComponent as GoogleIcon } from '../../assets/icons/google_icon.svg';
import { ReactComponent as MicrosoftIcon } from '../../assets/icons/microsoft_icon.svg';

const SigninPage: React.FC = () => {

    const { login } = useAuth();
    const navigate = useNavigate();

    // State for form input
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        login();
        navigate('/dashboard');
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="flex flex-col md:flex-row w-full max-w-5xl shadow-lg rounded-lg md:min-h-[640px]">
                {/* Left Column */}
                <div className="hidden md:flex flex-col justify-center items-center bg-blue-600 text-white p-5 rounded-l-lg w-full md:w-1/2">
                    <h1 className="text-4xl font-bold">ParseTrue</h1>
                    <p className="mt-3 text-xl">Fast, Efficient and Productive</p>
                </div>

                {/* Right Column */}
                <div className="bg-white p-6 md:w-1/2 w-full rounded-r-lg flex flex-col justify-center">
                    <div className="max-w-md mx-auto">
                        <h2 className="text-center text-2xl font-semibold mb-4">Sign In</h2>
                        <p className="text-center text-gray-500 mb-6">With social accounts</p>
                        <button className="w-full border border-gray-300 py-2 flex items-center justify-center space-x-2 rounded-md hover:bg-gray-100 mb-2">
                            <GoogleIcon className="w-5 h-5" />
                            <span>Sign in with Google</span>
                        </button>
                        <button className="w-full border border-gray-300 py-2 flex items-center justify-center space-x-2 rounded-md hover:bg-gray-100 mb-4">
                            <MicrosoftIcon className="w-5 h-5" />
                            <span>Sign in with Microsoft</span>
                        </button>
                        <div className="my-4 flex items-center">
                            <span className="border-t border-gray-300 flex-grow"></span>
                            <span className="mx-2 text-gray-500">Or sign in with</span>
                            <span className="border-t border-gray-300 flex-grow"></span>
                        </div>
                        <form className="space-y-4" onSubmit={handleLogin}>
                            <input
                                type="email"
                                placeholder="Email"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <input
                                type="password"
                                placeholder="Password"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-200">
                                Sign In
                            </button>
                        </form>

                        {/* Footer */}
                        <div className="mt-4 text-center text-sm text-gray-500">
                            <p>
                                Don't have an Account?{' '}
                                <a href="/create-account" className="text-blue-600 hover:underline">
                                    Create one here
                                </a>.
                            </p>
                            <p>
                                Forgot your password?{' '}
                                <a href="/reset-password" className="text-blue-600 hover:underline">
                                    Reset it here
                                </a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SigninPage;