import React, { useState } from 'react';
import { FaTag, FaCopy } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface Inbox {
    id: string;
    title: string;
    tag: string;
    stats: {
        total: number;
        parsed: number;
        failed: number;
        creditsUsed: number;
        failedWebhooks: number;
    };
    email: string;
}

const inboxesData: Inbox[] = [
    // Example inbox data; Add more inbox objects here
    {
        id: 'be1bace0-8f30-465a-9e22-f29c71baedc0',
        title: 'Hirer Invoice',
        tag: 'Receipt Processing',
        stats: {
            total: 1,
            parsed: 1,
            failed: 0,
            creditsUsed: 1,
            failedWebhooks: 1,
        },
        email: 'ogcmfela@mailparser.io',
    },
    // Add more inbox objects here...
];

const itemsPerPage = 10; // Items per page

const Inboxes: React.FC = () => {
    const [showAlert, setShowAlert] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState(1);

    const totalItems = inboxesData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handleCopy = (e: React.MouseEvent, email: string) => {
        e.preventDefault();
        e.stopPropagation(); // Prevent the card click event
        navigator.clipboard.writeText(email);
        setShowAlert(email);
        setTimeout(() => setShowAlert(null), 2000);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentInboxes = inboxesData.slice(startIndex, endIndex);

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex mb-4 items-center justify-between">
                <h1 className="text-2xl font-bold">Inboxes</h1>
                <Link to="/dashboard/create-inbox" className="font-bold text-white bg-red-500 rounded px-3 py-2">
                    + Create New Inbox
                </Link>
            </div>

            <div className="flex-wrap">
                {currentInboxes.map((inbox) => (
                    <Link
                        key={inbox.id}
                        to={`/dashboard/inbox/${inbox.id}`}
                        className="w-full md:w-1/2 lg:w-1/3 p-2 no-underline"
                    >
                        <div className="bg-white p-4 shadow-md rounded-lg h-full flex flex-col justify-between hover:shadow-lg transition-shadow duration-300">
                            <div className="flex justify-between items-center">
                                <div className="flex-grow">
                                    <h4 className="text-xl font-bold mb-2">
                                        <Link
                                            to={`/dashboard/inbox/${inbox.id}`}
                                            className="no-underline text-inherit hover:no-underline"
                                        >
                                            {inbox.title}
                                        </Link>
                                    </h4>
                                    <p className="text-sm text-gray-600 mb-2 flex items-center">
                                        <FaTag className="mr-2" /> {inbox.tag}
                                    </p>
                                </div>
                                <div className="text-right">
                                    <span>In the last 30 days</span>
                                </div>
                            </div>

                            <div className="mt-4 overflow-x-auto">
                                <table className="w-full border-collapse table-fixed min-w-full">
                                    <thead>
                                        <tr>
                                            <th className="p-2 border border-gray-300 bg-gray-100 font-bold text-xs">Total</th>
                                            <th className="p-2 border border-gray-300 bg-gray-100 font-bold text-xs">Parsed</th>
                                            <th className="p-2 border border-gray-300 bg-gray-100 font-bold text-xs">Failed</th>
                                            <th className="p-2 border border-gray-300 bg-gray-100 font-bold text-xs">Credits Used</th>
                                            <th className="p-2 border border-gray-300 bg-gray-100 font-bold text-xs">Failed Webhooks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="p-2 border border-gray-300 text-center">
                                                <span className="inline-block px-2 py-1 bg-blue-500 text-white rounded-full">
                                                    {inbox.stats.total}
                                                </span>
                                            </td>
                                            <td className="p-2 border border-gray-300 text-center">
                                                <span className="inline-block px-2 py-1 bg-green-500 text-white rounded-full">
                                                    {inbox.stats.parsed}
                                                </span>
                                            </td>
                                            <td className="p-2 border border-gray-300 text-center">
                                                <span className="inline-block px-2 py-1 bg-red-500 text-white rounded-full">
                                                    {inbox.stats.failed}
                                                </span>
                                            </td>
                                            <td className="p-2 border border-gray-300 text-center">
                                                <span className="inline-block px-2 py-1 bg-yellow-500 text-white rounded-full">
                                                    {inbox.stats.creditsUsed}
                                                </span>
                                            </td>
                                            <td className="p-2 border border-gray-300 text-center">
                                                <span className="inline-block px-2 py-1 bg-teal-500 text-white rounded-full">
                                                    {inbox.stats.failedWebhooks}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="mt-4 flex">
                                <div className="w-full max-w-xs relative">
                                    <input
                                        type="text"
                                        value={inbox.email}
                                        disabled
                                        readOnly
                                        className="w-full p-2 border border-gray-300 rounded"
                                    />
                                    <button
                                        onClick={(e) => handleCopy(e, inbox.email)}
                                        className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-2 text-blue-600 hover:text-blue-800"
                                        aria-label="Copy Email"
                                    >
                                        <FaCopy />
                                    </button>
                                    {showAlert === inbox.email && (
                                        <div className="absolute top-full right-0 mt-2 mr-2 z-50 bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded shadow-lg">
                                            Content copied to clipboard!
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>

            {/* Pagination */}
            <div className="mt-6 flex justify-between items-center">
                <div className="text-gray-600">
                    Showing {startIndex + 1} to {Math.min(endIndex, totalItems)} of {totalItems} items
                </div>

                {/* Pagination Buttons */}
                {totalPages > 1 && (
                    <div className="flex space-x-2">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => handlePageChange(index + 1)}
                                className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-outline'}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Inboxes;
