import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { IconType } from 'react-icons';

interface NavItemProps {
    to: string;
    label: string;
    icon: IconType;
    isCollapsed?: boolean;
    onClick?: () => void;
    className?: string;
}

const NavItem: React.FC<NavItemProps> = ({
    to,
    label,
    icon: Icon,
    isCollapsed = false,
    onClick,
    className = '',
}) => {

    const location = useLocation();
    const isActive = location.pathname === to;

    return (
        <li className={className}>
            <Link
                to={to}
                onClick={onClick}
                className={`flex items-center p-2 ${isCollapsed ? 'justify-center' : 'pl-8'} 
                        ${isActive ? 'bg-primary text-white' : 'hover:bg-base-300'}
                        ${isActive ? '' : 'hover:bg-base-300'}`}
            >
                <Icon className="text-lg" />
                {!isCollapsed && <span className="ml-2">{label}</span>}
            </Link>
        </li>
    );
};

export default NavItem;
