import React, { useState } from 'react';
import { FiChevronLeft, FiHelpCircle, FiCheckCircle, FiTrash2, FiPlusCircle } from 'react-icons/fi';

interface Subfield {
  label: string;
  description: string;
}

interface FieldFormProps {
  initialField: {
    uniqueId: string;
    label: string;
    description: string;
    subfields?: Subfield[];
  };
  onSave: (field: any) => void;
  onCancel: () => void;
}

const FieldForm: React.FC<FieldFormProps> = ({ initialField, onSave, onCancel }) => {
  const [fieldName, setFieldName] = useState(initialField.label);
  const [fieldDescription, setFieldDescription] = useState(initialField.description);
  const [subfields, setSubfields] = useState<Subfield[]>(initialField.subfields || []);

  // Error state for validation
  const [fieldNameError, setFieldNameError] = useState('');
  const [fieldDescriptionError, setFieldDescriptionError] = useState('');
  const [subfieldErrors, setSubfieldErrors] = useState<{ label: string; description: string }[]>([]);

  const handleSave = () => {
    // Reset all error states before validation
    setFieldNameError('');
    setFieldDescriptionError('');
    setSubfieldErrors([]);

    // Validation for field name and description
    let hasErrors = false;

    if (!fieldName.trim()) {
      setFieldNameError('Field name is required.');
      hasErrors = true;
    }

    if (!fieldDescription.trim()) {
      setFieldDescriptionError('Field description is required.');
      hasErrors = true;
    }

    // Validation for subfields
    const newSubfieldErrors = subfields.map(subfield => ({
      label: subfield.label.trim() ? '' : 'Subfield name is required.',
      description: subfield.description.trim() ? '' : 'Subfield description is required.',
    }));

    setSubfieldErrors(newSubfieldErrors);

    if (newSubfieldErrors.some(error => error.label || error.description)) {
      hasErrors = true;
    }

    // If any validation fails, do not proceed with save
    if (hasErrors) {
      return;
    }

    const updatedField = {
      ...initialField,
      label: fieldName,
      description: fieldDescription,
      subfields,
    };
    onSave(updatedField);
  };

  // Subfield management
  const handleSubfieldChange = (index: number, key: 'label' | 'description', value: string) => {
    const updatedSubfields = [...subfields];
    updatedSubfields[index][key] = value;
    setSubfields(updatedSubfields);
  };

  const handleAddSubfield = () => {
    setSubfields([...subfields, { label: '', description: '' }]);
    setSubfieldErrors([...subfieldErrors, { label: '', description: '' }]);
  };

  const handleRemoveSubfield = (index: number) => {
    setSubfields(subfields.filter((_, idx) => idx !== index));
    setSubfieldErrors(subfieldErrors.filter((_, idx) => idx !== index));
  };

  return (
    <div className="max-w-4xl mx-auto p-3 overflow-x-auto min-w-[530px]">
      {/* Back Button */}
      <div className="mb-1">
        <button
          onClick={onCancel}
          className="inline-flex items-center text-gray-600 hover:text-gray-800"
        >
          <FiChevronLeft className="mr-1" />
          <span>Back to Settings</span>
        </button>
      </div>

      {/* Form Card */}
      <div className="p-2">
        {/* Form Header */}
        <div className="mb-4">
          <h2 className="text-2xl font-semibold text-gray-800">
            {initialField.uniqueId ? 'Edit Field' : 'Create New Field'}
          </h2>
          <p className="text-sm text-gray-500 mt-1">
            {initialField.uniqueId
              ? 'Update the field details.'
              : 'Define a new field to extract from your documents.'}
          </p>
        </div>

        {/* Field Name Input */}
        <div className="mb-5">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Field Name
            <span className="ml-1 text-gray-400 cursor-pointer">
              <FiHelpCircle title="Enter a unique name for the field." className="inline" />
            </span>
          </label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={fieldName}
            onChange={(e) => setFieldName(e.target.value)}
            placeholder="e.g., Order Amount"
            required
          />
          {/* Display validation error */}
          {fieldNameError && <p className="text-red-500 text-sm mt-1">{fieldNameError}</p>}
        </div>

        {/* Field Description Input */}
        <div className="mb-5">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Description
            <span className="ml-1 text-gray-400 cursor-pointer">
              <FiHelpCircle title="Provide a brief description of this field." className="inline" />
            </span>
          </label>
          <textarea
            className="textarea textarea-bordered w-full"
            value={fieldDescription}
            onChange={(e) => setFieldDescription(e.target.value)}
            placeholder="e.g., The total amount for the order."
            rows={3}
            required
          ></textarea>
          {/* Display validation error */}
          {fieldDescriptionError && <p className="text-red-500 text-sm mt-1">{fieldDescriptionError}</p>}
        </div>

        {/* Subfields Section */}
        {subfields.length > 0 && (
          <div className="mb-5">
            <h3 className="text-md font-semibold mb-4">Attributes / Table columns ({subfields.length})</h3>
            {subfields.map((subfield, index) => (
              <div key={index} className="mb-4 border p-4 rounded-md">
                <div className="flex justify-between items-center mb-2">
                  <h4 className="text-sm font-semibold">Attribute {index + 1}</h4>
                  <button
                    type="button"
                    className="text-red-500 hover:text-red-700"
                    onClick={() => handleRemoveSubfield(index)}
                  >
                    <FiTrash2 />
                  </button>
                </div>
                <div className="mb-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Attribute name
                  </label>
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    value={subfield.label}
                    onChange={(e) => handleSubfieldChange(index, 'label', e.target.value)}
                    placeholder="e.g., total_price"
                    required
                  />
                  {/* Display validation error */}
                  {subfieldErrors[index]?.label && (
                    <p className="text-red-500 text-sm mt-1">{subfieldErrors[index].label}</p>
                  )}
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Attribute description
                  </label>
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    value={subfield.description}
                    onChange={(e) => handleSubfieldChange(index, 'description', e.target.value)}
                    placeholder="e.g., The total price of the item"
                    required
                  />
                  {/* Display validation error */}
                  {subfieldErrors[index]?.description && (
                    <p className="text-red-500 text-sm mt-1">{subfieldErrors[index].description}</p>
                  )}
                </div>
              </div>
            ))}
            <button
              type="button"
              className="btn btn-link text-blue-500 hover:underline"
              onClick={handleAddSubfield}
            >
              <FiPlusCircle className="inline" /> Add attribute
            </button>
          </div>
        )}

        {/* Action Buttons */}
        <div className="flex justify-end space-x-2">
          <button onClick={onCancel} className="btn btn-ghost">
            Cancel
          </button>
          <button onClick={handleSave} className="btn btn-primary">
            <FiCheckCircle className="mr-2" />
            Save Field
          </button>
        </div>
      </div>
    </div>
  );
};

export default FieldForm;

