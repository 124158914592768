import { useState } from 'react';

const GeneralTab = () => {
    const [inboxName, setInboxName] = useState('osod');
    const [inboxAddress, setInboxAddress] = useState('johncena.12466');
    const [deleteAfter, setDeleteAfter] = useState('30 days after receiving');
    const [processAttachments, setProcessAttachments] = useState(true);
    const [splitPdf, setSplitPdf] = useState(false);
    const [splitPages, setSplitPages] = useState(0);
    const [postProcessing, setPostProcessing] = useState(false);

    const handleSave = () => {
        // Save logic here
        console.log('Form saved');
    };

    return (
        <div className="p-4 max-w-3xl mx-0">
            {/* Inbox Name */}
            <div className="mb-4">
                <label className="label">
                    <span className="label-text font-bold">Inbox Name</span>
                </label>
                <input
                    type="text"
                    value={inboxName}
                    onChange={(e) => setInboxName(e.target.value)}
                    className="input input-bordered w-full"
                />
            </div>

            {/* GPT Engine Dropdown */}
            <div className="mb-4">
                <label className="label">
                    <span className="label-text font-bold">GPT engine</span>
                    <a href="#" className="label-text-alt text-blue-500 cursor-pointer hover:underline">
                        Learn about Text vs. Vision GPT engines →
                    </a>
                </label>
                <div className="relative">
                    <select className="select select-bordered w-full">
                        {/* Selectable A options */}
                        <optgroup label="Selectable A">
                            <option value="gpt-text">Text</option>
                            <option value="gpt-text-advanced">Text (Advanced)</option>
                        </optgroup>

                        {/* Divider */}
                        <option disabled>────────────</option>

                        {/* Selectable B options */}
                        <optgroup label="Selectable B">
                            <option value="gpt-vision">Vision</option>
                            <option value="gpt-vision-pro">Vision Pro</option>
                        </optgroup>
                    </select>
                </div>
            </div>

            {/* Inbox Address */}
            <div className="mb-4">
                <label className="label">
                    <span className="label-text font-bold">Inbox Address</span>
                </label>
                <div className="flex items-center">
                    <input
                        type="text"
                        value={inboxAddress}
                        onChange={(e) => setInboxAddress(e.target.value)}
                        className="input input-bordered w-full max-w-xs"
                    />
                    <span className="ml-2">@inbox.parsetrue.com</span>
                </div>
            </div>

            {/* Delete Documents */}
            <div className="mb-4">
                <label className="label">
                    <span className="label-text font-bold">Delete Documents</span>
                </label>
                <select
                    value={deleteAfter}
                    onChange={(e) => setDeleteAfter(e.target.value)}
                    className="select select-bordered w-full"
                >
                    <option value="30 days after receiving">30 days after receiving</option>
                    <option value="60 days after receiving">60 days after receiving</option>
                    <option value="90 days after receiving">90 days after receiving</option>
                </select>
            </div>

            {/* Process Attachments */}
            <div className="mb-4">
                <label className="cursor-pointer flex items-center">
                    <input
                        type="checkbox"
                        checked={processAttachments}
                        onChange={() => setProcessAttachments(!processAttachments)}
                        className="checkbox checkbox-primary mr-2"
                    />
                    <span className="label-text font-semibold">Process email attachments</span>
                </label>
            </div>

            {/* Split PDF Files */}
            <div className="mb-4">
                <label className="cursor-pointer flex items-center">
                    <input
                        type="checkbox"
                        checked={splitPdf}
                        onChange={() => setSplitPdf(!splitPdf)}
                        className="checkbox checkbox-primary mr-2"
                    />
                    <span className="label-text font-semibold">Split PDF files into multiple documents</span>
                </label>
                {splitPdf && (
                    <div className="ml-6 mt-2">
                        <span className="label-text">
                            Airparser can split one PDF file into multiple documents and parse them individually.
                        </span>
                        <div className="mt-2 flex items-center">
                            <label className="mr-2">Split every</label>
                            <input
                                type="number"
                                value={splitPages}
                                onChange={(e) => setSplitPages(Number(e.target.value))}
                                className="input input-bordered w-16"
                            />
                            <label className="ml-2">pages</label>
                        </div>
                    </div>
                )}
            </div>

            {/* Enable Post-Processing */}
            <div className="mb-4">
                <label className="cursor-pointer flex items-center">
                    <input
                        type="checkbox"
                        checked={postProcessing}
                        onChange={() => setPostProcessing(!postProcessing)}
                        className="checkbox checkbox-primary mr-2"
                    />
                    <span className="label-text font-semibold">Enable post-processing</span>
                </label>
                {postProcessing && (
                    <p className="ml-6 mt-2 text-sm text-gray-500">
                        The Post-processing step allows you to modify parsed data before exporting it to webhooks, Zapier, etc. Write
                        Python code to add custom formatting and business logic: merge or split fields, format dates, prevent some
                        documents from being exported, and much more.
                    </p>
                )}
            </div>

            {/* Save Button */}
            <div className="flex justify-end">
                <button onClick={handleSave} className="btn btn-primary">
                    Save
                </button>
            </div>
        </div>
    );
};

export default GeneralTab;
