import React, { useState } from 'react';
import { FaTerminal } from 'react-icons/fa'; // Only import the necessary icon
import Slider from '../../../components/Slider/Slider';

interface Engine {
    name: string;
    model: string;
    status: string;
    capabilities: string;
    type: string;
}

const ParseEngine: React.FC = () => {
    const [state, setState] = useState<{
        showSlider: boolean;
        showModal: boolean;
        selectedEngine: Engine | null;
        selectedRowData: any;
    }>({
        showSlider: false,
        showModal: false,
        selectedEngine: null,
        selectedRowData: null,
    });

    const engines: Engine[] = [
        {
            name: 'ParseTrue',
            model: 'gpt-4o',
            status: 'Inactive',
            capabilities: 'Text, Vision',
            type: 'Provided',
        },
        {
            name: 'OpenAI',
            model: 'gpt-4o-mini',
            status: 'Running',
            capabilities: 'Text',
            type: 'BYOP',
        },
        {
            name: 'Anthropic',
            model: 'claude-sonnet-3.5',
            status: 'Inactive',
            capabilities: 'Text',
            type: 'BYOP',
        },
    ];

    const handleEngineClick = (engine: Engine) => {
        setState({
            ...state,
            showSlider: true,
            selectedEngine: engine,
            selectedRowData: engine,
        });
    };

    const handleCloseSlider = () => {
        setState({ ...state, showSlider: false });
    };

    const handleDelete = () => {
        setState({ ...state, showModal: true });
    };

    const confirmDelete = () => {
        // Handle engine deletion here
        setState({ ...state, showModal: false, showSlider: false });
    };

    // Sort engines to place "Running" engines first
    const sortedEngines = engines.sort((a, b) =>
        a.status === 'Running' ? -1 : 1
    );
    const providedEngines = sortedEngines.filter(
        (engine) => engine.type === 'Provided'
    );
    const byopEngines = sortedEngines.filter(
        (engine) => engine.type === 'BYOP'
    );

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="mb-6">
                <h1 className="text-2xl font-bold">Parse Engine</h1>
            </div>

            <div>
                <h2 className="text-xl font-semibold mb-4">Provided</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {providedEngines.map((engine, index) => (
                        <div
                            key={index}
                            className="card bg-base-100 shadow-md hover:shadow-lg cursor-pointer"
                            onClick={() => handleEngineClick(engine)}
                        >
                            <div className="card-body">
                                <div className="flex items-center mb-4">
                                    <FaTerminal className="text-2xl mr-2" />
                                    <div>
                                        <h3 className="text-lg font-bold">{engine.name}</h3>
                                        <p className="text-sm text-gray-600">
                                            Model: {engine.model}
                                        </p>
                                    </div>
                                </div>
                                <p
                                    className={`text-sm ${engine.status === 'Running'
                                            ? 'text-green-600'
                                            : 'text-gray-500'
                                        }`}
                                >
                                    {engine.status}
                                </p>
                                <p className="mt-2 font-semibold">Capabilities:</p>
                                <div className="flex flex-wrap mt-1">
                                    {engine.capabilities.split(', ').map((capability, idx) => (
                                        <div
                                            key={idx}
                                            className="badge badge-warning badge-outline mr-1 mb-1"
                                        >
                                            {capability}
                                        </div>
                                    ))}
                                </div>
                                <p className="mt-2">
                                    <span className="font-semibold">Type:</span> {engine.type}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex items-center justify-between mt-8 mb-4">
                    <h2 className="text-xl font-semibold">
                        BYOP (Bring your own parser)
                    </h2>
                    <button className="btn btn-primary">Onboard Engine</button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {byopEngines.map((engine, index) => (
                        <div
                            key={index}
                            className="card bg-base-100 shadow-md hover:shadow-lg cursor-pointer"
                            onClick={() => handleEngineClick(engine)}
                        >
                            <div className="card-body">
                                <div className="flex items-center mb-4">
                                    <FaTerminal className="text-2xl mr-2" />
                                    <div>
                                        <h3 className="text-lg font-bold">{engine.name}</h3>
                                        <p className="text-sm text-gray-600">
                                            Model: {engine.model}
                                        </p>
                                    </div>
                                </div>
                                <p
                                    className={`text-sm ${engine.status === 'Running'
                                            ? 'text-green-600'
                                            : 'text-gray-500'
                                        }`}
                                >
                                    {engine.status}
                                </p>
                                <p className="mt-2 font-semibold">Capabilities:</p>
                                <div className="flex flex-wrap mt-1">
                                    {engine.capabilities.split(', ').map((capability, idx) => (
                                        <div
                                            key={idx}
                                            className="badge badge-warning badge-outline mr-1 mb-1"
                                        >
                                            {capability}
                                        </div>
                                    ))}
                                </div>
                                <p className="mt-2">
                                    <span className="font-semibold">Type:</span> {engine.type}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Slider Component */}
            <Slider
                state={state}
                handleDelete={handleDelete}
                handleCloseSlider={handleCloseSlider}
                confirmDelete={confirmDelete}
                setState={setState}
            >
                {state.selectedEngine && (
                    <div className="p-4">
                        <h3 className="text-lg font-bold">{state.selectedEngine.name}</h3>
                        <p>Model: {state.selectedEngine.model}</p>
                        <p>Status: {state.selectedEngine.status}</p>
                        <p>Capabilities: {state.selectedEngine.capabilities}</p>
                        <p>Type: {state.selectedEngine.type}</p>
                    </div>
                )}
            </Slider>
        </div>
    );
};

export default ParseEngine;