import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';

const Home: React.FC = () => {
    return (
        <>
            <Header title="My React Bootstrap App" showLoginButton={true} />
            <div className="container px-8 mt-4 mx-auto">
                <h1 className="mt-5 mb-4">Welcome to Our Application</h1>
                <p className="lead">This is a simple home page for our React application.</p>
                <div className="mt-4">
                    <Link to="/signin" className="btn btn-primary me-2">Login</Link>
                    <Link to="/blog" className="btn btn-outline-secondary me-2">Visit Our Blog</Link>
                    <Link to="/terms" className="btn btn-outline-info">Terms and Conditions</Link>
                </div>
            </div>
        </>
    );
};

export default Home;