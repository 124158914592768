import React from 'react';
import { FaTrashAlt, FaTimesCircle } from 'react-icons/fa';
// Removed imports from 'react-bootstrap' and SCSS module

interface SliderProps {
    state: {
        showSlider: boolean;
        showModal: boolean;
        selectedRowData: any;
    };
    handleDelete: () => void;
    handleCloseSlider: () => void;
    confirmDelete: () => void;
    setState: React.Dispatch<React.SetStateAction<any>>;
    children?: React.ReactNode;
}

interface SliderState {
    showSlider: boolean;
    showModal: boolean;
    selectedRowData: any;
}

const Slider: React.FC<SliderProps> = ({
    state,
    handleDelete,
    handleCloseSlider,
    confirmDelete,
    setState,
    children,
}) => {
    return (
        <>
            {/* Slider for JSON display */}
            <div
                className={`fixed top-0 right-0 h-full w-full sm:w-2/3 md:w-1/2 lg:w-1/3 bg-base-100 shadow-lg transform ${state.showSlider ? 'translate-x-0' : 'translate-x-full'
                    } transition-transform duration-300 z-50`}
            >
                {state.showSlider && (
                    <div className="p-4 h-full flex flex-col">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-xl font-semibold">Parsed</h3>
                            <div className="space-x-2">
                                <button
                                    className="btn btn-outline btn-error btn-sm"
                                    onClick={handleDelete}
                                >
                                    <FaTrashAlt />
                                </button>
                                <button
                                    className="btn btn-outline btn-secondary btn-sm"
                                    onClick={handleCloseSlider}
                                >
                                    <FaTimesCircle />
                                </button>
                            </div>
                        </div>
                        <div className="overflow-auto flex-grow">{children}</div>
                    </div>
                )}
            </div>

            {/* Modal for delete confirmation */}
            {state.showModal && (
                <div className="modal modal-open">
                    <div className="modal-box">
                        <h3 className="font-bold text-lg">Confirm Delete</h3>
                        <p className="py-4">Are you sure you want to delete this data?</p>
                        <div className="modal-action">
                            <button
                                className="btn"
                                onClick={() =>
                                    setState((prev: SliderState) => ({
                                        ...prev,
                                        showModal: false,
                                    }))
                                }
                            >
                                Cancel
                            </button>
                            <button className="btn btn-error" onClick={confirmDelete}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Slider;
