import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen text-center bg-base-100">
            <h1 className="text-9xl font-bold text-primary">404</h1>
            <h2 className="text-4xl font-semibold mb-4">Page Not Found</h2>
            <p className="text-lg mb-6">
                The page you are looking for doesn't exist or has been moved.
            </p>
            <Link to="/" className="btn btn-primary">
                Go Home
            </Link>
        </div>
    );
};

export default NotFound;
