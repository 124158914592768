import React from 'react';

interface ParsedDataProps {
    activeTab: string;
    data: Record<string, any>;
}

const ParsedData: React.FC<ParsedDataProps> = ({ activeTab, data }) => {
    if (activeTab !== 'parsed') return null;

    return (
        <>
            <div className="max-w-4xl mx-auto p-3 overflow-x-auto min-w-[530px]">
                <h5 className="font-bold mb-4">Extracted Data</h5>
                <div className="max-h-96 overflow-auto">
                    <table className="table table-zebra w-full mb-4">
                        <thead className="bg-gray-100 text-left">
                            <tr>
                                <th className="px-2 py-1">Attribute</th>
                                <th className="px-2 py-1">Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(data).map(([key, value]) =>
                                !Array.isArray(value) && typeof value !== 'object' && (
                                    <tr key={key} className="hover:bg-gray-200">
                                        <td className="border px-2 py-1">{key}</td>
                                        <td className="border px-2 py-1">{value}</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="max-w-4xl mx-auto p-3 overflow-x-auto min-w-[530px]">
                <h5 className="font-bold mb-4">Table Data</h5>
                <div className="max-h-96 overflow-auto">
                    {Object.entries(data).map(([key, value]) =>
                        Array.isArray(value) && (
                            <React.Fragment key={key}>
                                <h5 className="font-semibold mb-4">{key}</h5>
                                <table className="table table-zebra w-full mb-4">
                                    <thead className="bg-gray-100 text-left">
                                        <tr>
                                            {Object.keys(value[0]).map(header => <th key={header}>{header}</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {value.map((item, idx) => (
                                            <tr key={idx} className="hover:bg-gray-200">
                                                {Object.values(item as Record<string, React.ReactNode>).map((cell, idx) => (
                                                    <td key={idx} className="border px-2 py-1">{cell}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </React.Fragment>
                        )
                    )}
                </div>
            </div>
        </>
    );
};

export default ParsedData;