import React, { useState } from 'react';

const AdditionalFields = () => {
    const fields = [
        { label: 'Sender email', description: "Sender's email: 'From' field" },
        { label: 'Sender name', description: "Sender's name: 'From' field" },
        { label: 'Recipient', description: "One or more comma-separated recipient emails: 'To' field" },
        { label: 'Original recipient', description: "Typically your @in.airparser.com email address" },
        { label: 'CC', description: "One or more comma-separated recipient emails: 'CC' field" },
        { label: 'BCC', description: "One or more comma-separated recipient emails: 'BCC' field" },
        { label: 'Reply to', description: "One or more comma-separated emails: 'Reply To' field" },
        { label: 'Subject', description: 'Email subject' },
        { label: 'Name', description: 'Document name' },
        { label: 'Filename', description: 'Document filename' },
        { label: 'Received date and time', description: 'Received timestamp (date and time in ISO format)' },
        { label: 'Received date', description: 'Received timestamp (date only: YYYY-MM-DD)' },
        { label: 'Received time', description: 'Received timestamp (time only: hh:mm:ss)' },
        { label: 'Document content (original)', description: 'Document original content (html or txt)' },
        { label: 'Document content (plain text)', description: 'Emails and HTML documents converted into plain text (links removed)' },
        { label: 'Document content (plain text with URLs)', description: 'Emails and HTML documents converted into plain text (links preserved)' },
        { label: 'Content type', description: 'Original document media type (MIME type)' },
    ];

    const [selectedFields, setSelectedFields] = useState<string[]>([]);

    const toggleField = (field: string) => {
        if (selectedFields.includes(field)) {
            setSelectedFields(selectedFields.filter((f) => f !== field));
        } else {
            setSelectedFields([...selectedFields, field]);
        }
    };

    return (
        <div className="p-4 max-w-3xl mx-0"> {/* Removed border, shadow, and aligned left */}
            <p className="mb-6 text-gray-600">
                Select the additional fields to extract key information from your emails and documents. These fields will enhance the parsed data with more contextual information.
            </p>
            <div className="space-y-4">
                {fields.map((field) => (
                    <div key={field.label} className="flex items-start">
                        <input
                            type="checkbox"
                            checked={selectedFields.includes(field.label)}
                            onChange={() => toggleField(field.label)}
                            className="checkbox checkbox-primary mr-4"
                        />
                        <div className="flex-1">
                            <span className="font-semibold block">{field.label}</span>
                            <span className="text-gray-600 text-sm">{field.description}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdditionalFields;
